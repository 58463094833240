/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ModelAccountState } from '@/store/modelAccount/types';
import { toBase64 } from '@/services/api.upload'

const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountAddPhotoPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('uploadModelsPhoto', {namespace}) uploadModelsPhoto: any;
	@Action('getModelsPhoto', {namespace}) getModelsPhoto: any;
	@Getter('getModelSettingsUsername', {namespace}) getModelSettingsUsername: any;

	uploadImage: string = '';
	imageValue: string = '';

	$refs!: {
		refUploadImage: HTMLInputElement
	}

	async handleFileUpload() {
		const img = this.$refs.refUploadImage.files![0];
		this.imageValue = this.$refs.refUploadImage.value;
		return await toBase64(img).then(data => this.uploadImage = data);
	}

	onSubmit() {
		this.uploadModelsPhoto([{
			name: this.uploadImage
		}])
		this.uploadImage = '';
	}

	mounted() {
		this.getModelsPhoto();
	}
}
